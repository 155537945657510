<template>
  <!-- <div class="flex flex-col my-8 mt-24 sm:text-center">
    <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
      <h2
        class="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
      >
        Find the right plan for your Solana RPC requirements
      </h2>
    </div>
  </div> -->

  <div class="pt-16 mb-10 lg:max-w-5xl md:mb-12">
    <h2
      class="mb-6 text-3xl font-bold leading-none tracking-tight  md:text-5xl text-purple"
    >
      Pricing
    </h2>
    <h2
      class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900  md:text-3xl"
    >
      Find the right plan for your Solana RPC requirements
    </h2>
  </div>
  <section class="bg-white">
    <div class="container px-6 py-8 mx-auto">
      <div
        class="grid items-center gap-6 -mx-6  sm:gap-8 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
      >

        <!-- paid plan -->
        <div
          class="p-6 transition-colors duration-200 transform rounded-lg  bg-purple"
        >
          <p class="text-lg font-medium text-gray-100 md:text-xl">
            Shared pool
          </p>
          <h4 class="mt-2 text-5xl font-semibold text-gray-100">
            <span class="text-base font-normal md:text-lg">starts at</span> $500
            <span class="text-base font-normal text-white md:text-lg"
              >/ Month</span
            >
          </h4>
          <p class="mt-4 text-base text-gray-100 md:text-lg">
            Robust shared infrastructure for small and large projects.
          </p>

          <div class="mt-8 space-y-8">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-base text-gray-100 md:text-lg"
                >Geographically distributed
              </span>
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-base text-gray-100 md:text-lg"
                >Multiple layers of redundancy</span
              >
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-base text-gray-300 md:text-lg"
                >Devnet &amp; Archive access</span
              >
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-base text-gray-300 md:text-lg"
                >User authentication</span
              >
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-base text-gray-300 md:text-lg"
                >Monitoring dashboards</span
              >
            </div>
          </div>
          <router-link to="/pricing/paid-pricing">
            <button
              class="w-full px-4 py-2 mt-10 text-base font-medium tracking-wide capitalize transition-opacity duration-200 transform bg-white rounded-md  text-purple hover:opacity-90"
            >
              Explore shared plans
            </button>
          </router-link>
        </div>
        <!-- custom plan -->
        <div
          class="p-6 transition-colors duration-200 transform bg-gray-100 rounded-lg "
        >
          <p class="text-lg font-medium text-gray-800">
            Dedicated server or pool
          </p>
          <h4 class="text-4xl font-semibold text-gray-800">Custom pricing</h4>
          <p class="mt-4 text-gray-800">
            Private and high availability frontends.
          </p>

          <div class="mt-8 space-y-8">
            <Features
              text="Custom RPS and bandwidth"
              textColor="text-gray-900"
            />
            <Features
              text="Choice of worldwide datacenters"
              textColor="text-gray-900"
            />
            <Features
              text="Dedicated 24/7 support channels"
              textColor="text-gray-900"
            />
            <Features text="Tailored configuration" textColor="text-gray-900" />
            <Features
              text="Bot and abuse prevention"
              textColor="text-gray-900"
            />
          </div>

          <a
            href="https://forms.monday.com/forms/38cf903b098497847d2023fa6e9400ed?r=use1"
          >
            <button
              class="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-opacity duration-200 transform rounded-md  bg-purple hover:opacity-90"
            >
              Get in touch
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FAQ from "./../components/FAQ.vue";
import Features from "./../components/Features.vue";
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: "Pricing: Triton",
      meta: [
        {
          name: "description",
          content: "Find the right plan for your Solana RPC requirements",
        },
      ],
    });
  },
  name: "Pricing",

  data() {
    return {
      content: {
        body: [
          {
            "question": "Do I need a paid plan?",
            "answer":
              "If you are an individual developer or user you probably don't! If you are an app developer or service provider having your backends or your end users make use of the rpcpool, yes probably you do need a paid plan for access.",
          },
          {
            "question": "How do I know if I am ratelimited?",
            "answer":
              "You will see HTTP respose codes 429 for too many requests or 413 for too much bandwidth.",
          },
        ],
      },
    };
  },
  components: {
    FAQ,
    Features,
  },
};
</script>
