<template>
  <div class="w-full m-4 border rounded shadow-sm md:w-2/6">
    <button
      v-on:click="toggleFAQ"
      type="button"
      aria-label="Open item"
      title="Open item"
      class="flex justify-between w-full px-4 pt-4 focus:outline-none"
    >
      <p class="w-auto mr-8 text-lg font-medium text-left">
        {{ question }}
      </p>
      <div class="flex items-center justify-center w-8 h-8 ml-8">
        <svg
          viewBox="0 0 24 24"
          width="24"
          hegiht="24"
          :class="{ 'transform rotate-180': !showContent, '': showContent }"
          class="w-3 text-gray-600 transition-transform duration-200"
        >
          <polyline
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="2,7 12,17 22,7"
            stroke-linejoin="round"
          ></polyline>
        </svg>
      </div>
    </button>

    <div :class="{ 'hidden': !showContent, 'flex': showContent }" class="px-4">
      <p class="text-gray-700 w-96">{{ answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    // block: Object,
    question: String,
    answer: String,
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    toggleFAQ: function () {
      this.showContent = !this.showContent;
      console.log(showContent);
    },
  },
};
</script>
